body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header2 {
  background: #001529;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header2 .home-page {
  padding: 0 24px;
}
.header2-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header2-logo img {
  vertical-align: middle;
  width: 280px;
  display: inline-block;
}
.header2-logo a {
  display: block;
}
.header2-menu {
  float: right;
}
.header2-menu > .menu-item {
  line-height: 62px;
  display: inline-block;
  padding: 0 20px;
  cursor: pointer;
  background: transparent;
  color: #fff;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.header2-menu > .menu-item.active,
.header2-menu > .menu-item:hover {
  color: #2F54EB;
  border-bottom: 2px solid #2F54EB;
}
@media screen and (max-width: 767px) {
  .header2-logo {
    z-index: 101;
  }
  .header2.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header2-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-menu .menu-item {
    display: block;
    width: 100%;
    padding: 0 24px;
    border-bottom: none;
    line-height: 40px;
    height: 40px;
    margin: 4px 0 8px;
  }
  .header2-menu .menu-item.active,
  .header2-menu .menu-item:hover {
    border: none;
    background: #2F54EB;
    color: #fff;
  }
  .header2-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header2-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-mobile-menu :first-child {
    margin-top: 0;
  }
  .header2 .open {
    height: auto;
  }
  .header2 .open .header2-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header2 .open .header2-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header2 .open .header2-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header2 .open .header2-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.banner2 {
  width: 100%;
  height: calc(100vh - 64px);
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner2 .banner-anim {
  height: 100%;
}
.banner2 .queue-anim-leaving {
  position: relative !important;
  width: auto;
}
.banner2 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner2 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner2 .bg0 {
  background: url("/public/assets/bXNBIyzSLHaycUGDzwLG.jpg") center;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg0:before {
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.35);
  display: block;
}
.banner2 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner2 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner2-page {
  position: relative;
  top: 20%;
  max-width: 1200px;
  margin: auto;
}
.banner2-text-wrapper {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  width: 400px;
  text-align: left;
}
.banner2-text-wrapper .banner2-title {
  left: 0;
  margin: auto;
  position: relative;
  font-size: 56px;
}
.banner2-text-wrapper .banner2-content {
  word-wrap: break-word;
  margin: auto auto 20px;
  font-weight: lighter;
}
.banner2-text-wrapper button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  transition: background 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19), border 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner2-text-wrapper button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2-text-wrapper button:hover {
  color: #fff;
  border-color: #1DA57A;
  background: #1DA57A;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), border 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2-text-wrapper button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner2-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 414px) {
  .banner2-text-wrapper {
    width: 90%;
    left: 0;
    margin: auto;
    padding: 0;
    text-align: center;
    display: block;
  }
  .banner2-text-wrapper .logo {
    width: 90%;
    left: 0;
  }
}
.content0-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 100px;
  height: 100px;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    min-height: 880px;
  }
}
.feature6-wrapper {
  min-height: 360px;
  margin: 0 auto;
  overflow: hidden;
}
.feature6-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
}
.feature6-title {
  display: inline-block;
}
.feature6-title-wrapper {
  text-align: center;
  margin-bottom: 48px;
}
.feature6-title-text {
  display: inline-block;
  margin: 0 72px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.feature6-title-text.active {
  color: #1DA57A;
}
.feature6-title-bar {
  height: 6px;
  background: #1DA57A;
  width: 20%;
  margin: auto;
  display: block;
}
.feature6-title-bar-wrapper {
  position: relative;
  margin-top: 8px;
  transition: left 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature6-number,
.feature6-text {
  text-align: center;
}
.feature6-number {
  font-size: 48px;
  color: #1DA57A;
  font-weight: 600;
}
.feature6-number-wrapper {
  text-align: center;
}
.feature6-text {
  font-size: 20px;
}
.feature6-unit {
  font-size: 16px;
  color: #1DA57A;
}
.feature6 .ant-carousel .slick-dots-bottom {
  bottom: -24px;
}
.feature6 .ant-carousel .slick-dots li button {
  background: rgba(29, 165, 122, 0.3);
}
.feature6 .ant-carousel .slick-dots li.slick-active button {
  background: #1DA57A;
}
@media screen and (max-width: 767px) {
  .feature6-wrapper {
    padding-bottom: 0;
    min-height: 580px;
  }
  .feature6-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px 64px;
  }
  .feature6-title-text {
    margin: 0 14px;
  }
  .feature6-title-bar {
    width: 40%;
  }
  .feature6-number-wrapper {
    margin-bottom: 16px;
  }
}
.content2-wrapper {
  height: 360px;
}
.content2-wrapper .content2 {
  height: 100%;
  padding: 0 24px;
}
.content2-wrapper .content2-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content2-wrapper .content2-img span {
  display: block;
  width: 250px;
}
.content2-wrapper .content2-img span img {
  display: block;
}
.content2-wrapper .content2-text {
  padding: 0 32px;
  height: 100%;
}
.content2-wrapper .content2-text .content2-content,
.content2-wrapper .content2-text .content2-title {
  position: relative !important;
}
.content2-wrapper .content2-text .content2-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content2-wrapper .content2-text .content2-content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content2-wrapper {
    height: 600px;
  }
  .content2-wrapper .content2-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content2-wrapper .content2-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content2-wrapper .content2-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content2-wrapper .content2-text .content2-content,
  .content2-wrapper .content2-text .content2-title {
    width: 100%;
    top: auto;
  }
  .content2-wrapper .content2-text .content2-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content1-wrapper {
  height: 360px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 250px;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 600px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.banner1 {
  width: 100%;
  height: 100vh;
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner1-wrapper,
.banner1 .banner-anim {
  height: 100%;
}
.banner1 .queue-anim-leaving {
  position: relative !important;
}
.banner1 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner1 .bg0 {
  background: url("/public/assets/slide_1.jpg") center;
}
.banner1 .bg1 {
  background: url("/public/assets/slide_2.jpg") center;
}
.banner1 .bg2 {
  background: url("/public/assets/slide_3.jpg") center;
}
.banner1 .bg3 {
  background: url("/public/assets/slide_4.jpg") center;
}
.banner1 .bg4 {
  background: url("/public/assets/slide_5.jpg") center;
}
.banner1 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner1 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner1 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner1 .banner1-text-wrapper {
  display: block;
  position: relative;
  top: 10%;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 14px;
  color: #fff;
  width: 550px;
  text-align: center;
}
.banner1 .banner1-title {
  width: 350px;
  left: 30px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner1 .banner1-content {
  margin-bottom: 20px;
  font-size: 40px;
  word-wrap: break-word;
}
.banner1 .banner1-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner1 .banner1-button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner1 .banner1-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner1 .banner1-button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner1 .banner1-button.queue-anim-leaving {
  width: auto;
}
.banner1 .banner1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 767px) {
  .banner1 .banner1-text-wrapper {
    width: 90%;
  }
  .banner1 .banner1-text-wrapper .banner1-title {
    width: 90%;
    left: 0;
  }
  .banner1 .bg {
    background-attachment: inherit;
  }
}
.content3-wrapper {
  min-height: 764px;
}
.content3-wrapper .content3 {
  height: 100%;
  overflow: hidden;
}
.content3-wrapper .content3 .title-content {
  text-align: center;
}
.content3-wrapper .content3-block-wrapper {
  position: relative;
}
.content3-wrapper .content3-block-wrapper .content3-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.content3-wrapper .content3-block-wrapper .content3-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .content3-wrapper {
    min-height: 1080px;
  }
  .content3-wrapper .content3-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .content3-wrapper .content3-block-wrapper .content3-block .content3-title {
    font-size: 20px;
  }
  .content3-wrapper .content3-block-wrapper .content3-block.queue-anim-leaving {
    position: relative !important;
  }
}
.pricing1-wrapper {
  min-height: 760px;
}
.pricing1-wrapper .pricing1 > p {
  text-align: center;
}
.pricing1-wrapper .pricing1-content-wrapper {
  min-height: 400px;
}
.pricing1-wrapper .pricing1-block-box {
  width: 260px;
  border-radius: 4px;
  background: #eef0f3;
  text-align: center;
  color: #666;
  min-height: 400px;
  margin: auto;
  border: 1px solid transparent;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  transform: translateY(0);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.pricing1-wrapper .pricing1-block-box:hover {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}
.pricing1-wrapper .pricing1-block-box.active {
  border-color: #2F54EB;
  background: #fff;
}
.pricing1-wrapper .pricing1-block-box.active .pricing1-top-wrapper {
  background: #2F54EB;
}
.pricing1-wrapper .pricing1-block-box.active .pricing1-name,
.pricing1-wrapper .pricing1-block-box.active .pricing1-money,
.pricing1-wrapper .pricing1-block-box.active .pricing1-button {
  color: #fff;
}
.pricing1-wrapper .pricing1-block-box.active .pricing1-button {
  background: #2F54EB;
}
.pricing1-wrapper .pricing1-block {
  margin-bottom: 24px;
}
.pricing1-wrapper .pricing1-top-wrapper {
  width: 100%;
  padding: 16px 24px;
}
.pricing1-wrapper .pricing1-name {
  font-size: 14px;
}
.pricing1-wrapper .pricing1-money {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 32px;
  color: #666;
}
.pricing1-wrapper .pricing1-content {
  font-size: 12px;
  line-height: 2;
  font-weight: 300;
  margin: 32px 24px 48px;
}
.pricing1-wrapper .pricing1-line {
  display: block;
  height: 1px;
  background: #d9d9d9;
  margin: 0 24px;
}
.pricing1-wrapper .pricing1-button-wrapper {
  margin: 18px 24px;
}
.pricing1-wrapper .pricing1-button {
  padding: 0 24px;
}
.pricing1-wrapper.home-page-wrapper .pricing1-title-wrapper {
  margin-bottom: 64px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .pricing1-wrapper {
    padding-bottom: 0;
  }
}
.content9-wrapper {
  padding-bottom: 64px;
}
.content9-wrapper.home-page-wrapper {
  overflow: initial;
}
.content9-wrapper .content9 {
  min-height: 800px;
  padding: 64px 0 0;
}
.content9-wrapper .timeline {
  position: relative;
}
.content9-wrapper .timeline:before {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 0;
  height: calc(100% - 108px);
  margin: 34px 0;
  border-left: 2px #ebedf0 dashed;
}
.content9-wrapper .block-wrapper {
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  position: relative;
  margin-bottom: 70px;
  min-height: 160px;
}
.content9-wrapper .block-wrapper:last-child {
  margin-bottom: 0;
}
.content9-wrapper .image-wrapper,
.content9-wrapper .text-wrapper {
  width: 50%;
}
.content9-wrapper .image-wrapper {
  text-align: center;
}
.content9-wrapper .image-wrapper .block-img,
.content9-wrapper .image-wrapper .name-wrapper {
  float: right;
  clear: both;
  width: 262px;
  margin: auto;
}
.content9-wrapper .image-wrapper .block-img {
  height: 98px;
}
.content9-wrapper .image-wrapper .block-img img {
  height: 100%;
}
.content9-wrapper .image-wrapper .name-wrapper {
  margin: 16px 0 0;
}
.content9-wrapper .image-wrapper .name-wrapper .block-name {
  font-size: 14px;
  margin-bottom: 4px;
}
.content9-wrapper .image-wrapper .name-wrapper .block-post {
  font-size: 12px;
  color: #697b8c;
}
.content9-wrapper .text-wrapper {
  padding-left: 40px;
}
.content9-wrapper .text-wrapper .block-time {
  font-size: 12px;
  color: #697b8c;
  line-height: 18px;
  min-height: 18px;
}
.content9-wrapper .text-wrapper .block-title {
  margin: 8px 0 12px;
  font-size: 14px;
  position: relative;
  min-height: 18px;
}
.content9-wrapper .text-wrapper .block-icon {
  position: absolute;
  left: -40px;
  transform: translateX(calc(-50% + 1px));
}
.content9-wrapper .text-wrapper .block-content {
  width: 300px;
  color: #314659;
  font-size: 12px;
  min-height: 18px;
}
@media screen and (max-width: 767px) {
  .content9-wrapper {
    padding-bottom: 0;
  }
  .content9-wrapper .content9 {
    padding: 64px 24px;
  }
  .content9-wrapper .timeline:before {
    left: 0;
    height: 100%;
    margin: 0;
  }
  .content9-wrapper .block-wrapper {
    display: block;
    min-height: 100px;
    margin-bottom: 54px;
  }
  .content9-wrapper .image-wrapper,
  .content9-wrapper .text-wrapper {
    width: 100%;
  }
  .content9-wrapper .image-wrapper .block-img {
    display: none;
  }
  .content9-wrapper .image-wrapper .name-wrapper {
    text-align: left;
    float: left;
    width: 100%;
    padding-left: 40px;
    margin: auto;
  }
  .content9-wrapper .image-wrapper .block-name,
  .content9-wrapper .image-wrapper .block-post {
    display: inline-block;
  }
  .content9-wrapper .image-wrapper .block-name {
    margin-right: 8px;
  }
  .content9-wrapper .text-wrapper .block-content {
    display: none;
  }
  .content9-wrapper .text-wrapper .block-title {
    font-size: 16px;
  }
}
.banner5 {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 100%);
  overflow: hidden;
}
.banner5 .banner5-page {
  padding: 1px 0 0;
}
.banner5-title {
  font-size: 48px;
  line-height: 1.5;
  color: #5f9bf1;
  margin-bottom: 8px;
}
.banner5-title-wrapper {
  width: 40%;
  max-width: 450px;
  position: relative;
  top: 30%;
  left: 24px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.banner5-explain {
  color: #333;
  font-size: 24px;
  margin-bottom: 24px;
}
.banner5-content {
  font-size: 18px;
  color: #666;
  margin-bottom: 48px;
}
.banner5-button-wrapper .banner5-button {
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
  box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: transform 0.3s, box-shadow 0.3s;
}
.banner5-button-wrapper .banner5-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(95, 155, 241, 0.45);
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
}
.banner5-image {
  overflow: hidden;
  width: 55%;
  max-width: 710px;
  position: absolute;
  top: 15%;
  right: 24px;
}
@media screen and (max-width: 767px) {
  .banner5 {
    min-height: 500px;
  }
  .banner5 .banner5-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner5-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .banner5-title {
    font-size: 32px;
  }
  .banner5-explain {
    font-size: 18px;
  }
  .banner5-content {
    font-size: 14px;
  }
  .banner5-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
#Feature6_0 .ant-row > .ant-col > .lxktbwem0ar-editor_css {
  display: none;
}
#Feature6_0 .ant-row > .ant-col > .lxktccbultc-editor_css {
  display: none;
}
#Feature6_0 .ant-row > .ant-col > .lxktceovvi-editor_css {
  display: none;
}
#Banner2_0 .banner-anim > .banner-anim-elem > .lxlnak38mr6-editor_css {
  position: sticky;
}
#Content9_0 .block-wrapper > .text-wrapper > .lxm0dsd6bpg-editor_css {
  display: none;
}
#Content9_0 .block-wrapper > .text-wrapper > .lxm0dzireu-editor_css {
  display: none;
}
#Content9_0 .block-wrapper > .text-wrapper > .lxm0e3780sh-editor_css {
  display: none;
}
#Banner5_0 .banner5-title-wrapper > div > .lxm0y7r83ji-editor_css {
  height: 40px;
}
#Banner5_0 .banner5-title-wrapper > div > .lxm187o3t1f-editor_css {
  border-top-color: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  background-clip: padding-box;
  line-height: 40px;
  color: #ffffff;
  height: 40px;
  background-color: #2f54eb;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 0px 42px;
  box-shadow: 0px 8px 24px rgba(95, 155, 241, 0.35);
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.12);
  transition: transform 0.3s ease 0s, box-shadow 0.3s ease 0s;
}
#Banner5_0 .banner5-title-wrapper > .banner5-button-wrapper > .lxm27w78gxe-editor_css {
  margin: 0 0 10px;
  padding: 0px 42px;
}
#Banner2_0 .home-page > .banner2-text-wrapper > .lxkrr51rsif-editor_css {
  width: 300px;
  float: left;
}
@media screen and (max-width: 767px) {
  #Banner2_0 .home-page > .banner2-text-wrapper > .lxkrr51rsif-editor_css {
    width: 300px;
    float: left;
  }
}
@media screen and (max-width: 767px) {
  #Nav2_0 .lxn2raga22o-editor_css {
    position: sticky;
  }
}
#Content1_0.lxn2vxz7o-editor_css {
  background-color: #fafafa;
}
#Feature6_0 .lxn2w5f5v0a-editor_css {
  background-color: rgba(0, 0, 0, 0);
}
#Feature6_0 .lxn2w5f5v0a-editor_css > .lxn2xf1s33-editor_css {
  background-color: rgba(0, 0, 0, 0);
}
#Feature6_0.lxn2zo7mmi-editor_css {
  background-color: #fafafa;
}
#Banner2_0 .home-page > .banner2-text-wrapper > .lxkrz7ldze6-editor_css {
  display: block;
  line-height: 26px;
  position: static;
  float: left;
  background-color: rgba(0, 0, 0, 0);
  border-top-color: #fbfbfb;
  border-right-color: #fbfbfb;
  border-bottom-color: #fbfbfb;
  border-left-color: #fbfbfb;
}
@media screen and (max-width: 767px) {
  #Banner2_0 .home-page > .banner2-text-wrapper > .lxkrz7ldze6-editor_css {
    line-height: 26px;
    width: 100%;
    position: static;
    float: left;
    background-color: rgba(0, 0, 0, 0);
    border-top-color: #fbfbfb;
    border-right-color: #fbfbfb;
    border-bottom-color: #fbfbfb;
    border-left-color: #fbfbfb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}
#Banner2_0 .home-page > .banner2-text-wrapper > .lxkrf5yunc-editor_css {
  font-size: 40px;
  line-height: 0.2;
  width: 100%;
  max-width: 100%;
  min-width: 60%;
}
@media screen and (max-width: 767px) {
  #Banner2_0 .home-page > .banner2-text-wrapper > .lxkrf5yunc-editor_css {
    font-size: 40px;
    line-height: 0.2;
    width: 65%;
    max-width: 100%;
    min-width: 60%;
  }
}
#Content9_0 .image-wrapper > .name-wrapper > .lxn38426iek-editor_css {
  display: none;
}
@media screen and (max-width: 767px) {
  #Content9_0 .image-wrapper > .name-wrapper > .lxn38426iek-editor_css {
    display: none;
  }
}
#Content9_0 .image-wrapper > .name-wrapper > .lxn3bua20id-editor_css {
  display: none;
}
@media screen and (max-width: 767px) {
  #Content9_0 .image-wrapper > .name-wrapper > .lxn3bua20id-editor_css {
    display: none;
  }
}
#Content9_0 .image-wrapper > .name-wrapper > .lxn3cbtz5g6-editor_css {
  display: none;
}
@media screen and (max-width: 767px) {
  #Content9_0 .image-wrapper > .name-wrapper > .lxn3cbtz5g6-editor_css {
    display: none;
  }
}
#Content9_0 .image-wrapper > .name-wrapper > .lxn387qf8jk-editor_css {
  opacity: 0;
}
@media screen and (max-width: 767px) {
  #Content9_0 .image-wrapper > .name-wrapper > .lxn387qf8jk-editor_css {
    width: 100%;
    opacity: 0.99;
  }
}
#Content9_0 .image-wrapper > .name-wrapper > .lxn3bxq887j-editor_css {
  opacity: 0;
}
@media screen and (max-width: 767px) {
  #Content9_0 .image-wrapper > .name-wrapper > .lxn3bxq887j-editor_css {
    width: 100%;
    opacity: 0.99;
  }
}
#Content9_0 .image-wrapper > .name-wrapper > .lxn3ce4kon-editor_css {
  opacity: 0;
}
@media screen and (max-width: 767px) {
  #Content9_0 .image-wrapper > .name-wrapper > .lxn3ce4kon-editor_css {
    width: 100%;
    opacity: 0.99;
  }
}
#Pricing1_0.lxn3yyh8jgj-editor_css {
  background-color: #fafafa;
}
#Pricing1_0 .pricing1-block-box > .pricing1-top-wrapper > .lxn44khlux-editor_css {
  font-size: 22px;
}
#Pricing1_0 .pricing1-block-box > .pricing1-top-wrapper > .lxn440w40l-editor_css {
  font-size: 22px;
  color: #e48216;
}
#Pricing1_0 .pricing1-block-box > .pricing1-top-wrapper > .lxn4c7u35ea-editor_css {
  font-size: 22px;
}
#Pricing1_0 .ant-col > .pricing1-block-box > .lxn4efh6ws7-editor_css {
  text-align: left;
}
#Pricing1_0 .pricing1-block-box > .pricing1-button-wrapper > .lxn49o58a0e-editor_css {
  opacity: 0.3;
}
#Pricing1_0 .ant-col > .pricing1-block-box > .lxn50q58vmr-editor_css {
  text-align: left;
}
#Nav2_0 .home-page > .lxnly6i9nsi-editor_css {
  width: 50px;
}
#Feature6_0 .ant-row > .ant-col > .lxnmolw9v5-editor_css {
  color: #1076f6;
}
#Feature6_0 .ant-row > .ant-col > .lxnmoh5vde7-editor_css {
  color: #1076f6;
}
#Feature6_0 .ant-row > .ant-col > .lxnmngrvdv-editor_css {
  color: #1076f6;
}
#Feature6_0 .feature6-title-wrapper > .feature6-title > .lxnmopnwfnj-editor_css {
  color: #1076f6;
}
#Feature6_0 .feature6-title > .feature6-title-bar-wrapper > .lxnmouimqv9-editor_css {
  background-color: #1076f6;
}
#Banner5_0 .banner5-title-wrapper > .banner5-button-wrapper > .lxm187o3t1f-editor_css {
  background-clip: ;
  background-color: #2fa3eb;
  background-image: linear-gradient(to right bottom, #aedbf5 0%, #10c0f6 0%, #94cff8 98.15%);
}
#Content9_0 .home-page > .timeline > .lxnlivamb7f-editor_css {
  min-height: 100px;
  margin: 0px 0px 10px;
}
@media screen and (max-width: 767px) {
  #Content9_0 .home-page > .timeline > .lxnlivamb7f-editor_css {
    min-height: 90px;
    margin: 0px 0px 40px;
  }
}
#Content9_0 .home-page > .timeline > .lxnp1k4xcr-editor_css {
  min-height: 100px;
  margin: 0 0;
}
#Content9_0 .home-page > .timeline > .lxnoys3xuck-editor_css {
  min-height: 80px;
  margin: 0px 0px 0;
}
@media screen and (max-width: 767px) {
  #Content9_0 .home-page > .timeline > .lxnoys3xuck-editor_css {
    min-height: 140px;
    margin: 0px 0px 40px;
  }
}
#Content9_0.lxn3zhwaszi-editor_css {
  background-color: rgba(0, 0, 0, 0);
}
