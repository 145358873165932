#Feature6_0 div > .ant-row > .lxkt2xlp5el-editor_css {
}
#Feature6_0 div > .ant-row > .lxksyh031c-editor_css {
}
#Feature6_0 div > .ant-row > .lxksy6io1yk-editor_css {
}
#Feature6_0 .ant-row > .ant-col > .lxktbwem0ar-editor_css {
  display: none;
}
#Feature6_0 .ant-row > .ant-col > .lxktccbultc-editor_css {
  display: none;
}
#Feature6_0 .ant-row > .ant-col > .lxktceovvi-editor_css {
  display: none;
}
#Banner2_0 .banner-anim > .banner-anim-elem > .lxlnak38mr6-editor_css {
  position: sticky;
}
#Banner5_0 .home-page > .lxlqaczt8o-editor_css {
}
#Content9_0 .block-wrapper > .text-wrapper > .lxm0dsd6bpg-editor_css {
  display: none;
}
#Content9_0 .block-wrapper > .text-wrapper > .lxm0dzireu-editor_css {
  display: none;
}
#Content9_0 .block-wrapper > .text-wrapper > .lxm0e3780sh-editor_css {
  display: none;
}
#Banner5_0 .banner5-title-wrapper > div > .lxm0y7r83ji-editor_css {
  height: 40px;
}
#Banner5_0 .banner5-title-wrapper > div > .lxm187o3t1f-editor_css {
  border-top-color: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  background-clip: padding-box;
  line-height: 40px;
  color: rgb(255, 255, 255);
  height: 40px;
  background-color: rgb(47, 84, 235);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 0px 42px;
  box-shadow: 0px 8px 24px rgba(95, 155, 241, 0.35);
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.12);
  transition: transform 0.3s ease 0s, box-shadow 0.3s ease 0s;
}
#Banner5_0
  .banner5-title-wrapper
  > .banner5-button-wrapper
  > .lxm27w78gxe-editor_css {
  margin: 0 0 10px;
  padding: 0px 42px;
}
#Banner2_0 .banner-anim-elem > .home-page > .lxkrplgn3c8-editor_css {
}
#Banner2_0 .home-page > .banner2-text-wrapper > .lxkrr51rsif-editor_css {
  width: 300px;
  float: left;
}

@media screen and (max-width: 767px) {
  #Banner2_0 .home-page > .banner2-text-wrapper > .lxkrr51rsif-editor_css {
    width: 300px;
    float: left;
  }
}

@media screen and (max-width: 767px) {
  #Nav2_0 .lxn2raga22o-editor_css {
    position: sticky;
  }
}
#Content1_0.lxn2vxz7o-editor_css {
  background-color: rgb(250, 250, 250);
}
#Feature6_0 .lxn2w5f5v0a-editor_css {
  background-color: rgba(0, 0, 0, 0);
}
#Feature6_0 .lxn2w5f5v0a-editor_css > .lxn2xf1s33-editor_css {
  background-color: rgba(0, 0, 0, 0);
}
#Feature6_0.lxn2zo7mmi-editor_css {
  background-color: rgb(250, 250, 250);
}
#Banner2_0 .home-page > .banner2-text-wrapper > .lxkrz7ldze6-editor_css {
  display: block;
  line-height: 26px;
  position: static;
  float: left;
  background-color: rgba(0, 0, 0, 0);
  border-top-color: #fbfbfb;
  border-right-color: #fbfbfb;
  border-bottom-color: rgb(251, 251, 251);
  border-left-color: rgb(251, 251, 251);
}

@media screen and (max-width: 767px) {
  #Banner2_0 .home-page > .banner2-text-wrapper > .lxkrz7ldze6-editor_css {
//    display: block;
    line-height: 26px;
    width: 100%;
    position: static;
    float: left;
    background-color: rgba(0, 0, 0, 0);
    border-top-color: rgb(251, 251, 251);
    border-right-color: rgb(251, 251, 251);
    border-bottom-color: rgb(251, 251, 251);
    border-left-color: rgb(251, 251, 251);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}
#Banner2_0 .home-page > .banner2-text-wrapper > .lxkrf5yunc-editor_css {
  font-size: 40px;
  line-height: 0.2;
  width: 100%;
  max-width: 100%;
  min-width: 60%;
}

@media screen and (max-width: 767px) {
  #Banner2_0 .home-page > .banner2-text-wrapper > .lxkrf5yunc-editor_css {
    font-size: 40px;
    line-height: 0.2;
    width: 65%;
    max-width: 100%;
    min-width: 60%;
  }
}
#Content9_0 .image-wrapper > .name-wrapper > .lxn38426iek-editor_css {
  display: none;
}

@media screen and (max-width: 767px) {
  #Content9_0 .image-wrapper > .name-wrapper > .lxn38426iek-editor_css {
    display: none;
  }
}
#Content9_0 .image-wrapper > .name-wrapper > .lxn3bua20id-editor_css {
  display: none;
}

@media screen and (max-width: 767px) {
  #Content9_0 .image-wrapper > .name-wrapper > .lxn3bua20id-editor_css {
    display: none;
  }
}
#Content9_0 .image-wrapper > .name-wrapper > .lxn3cbtz5g6-editor_css {
  display: none;
}

@media screen and (max-width: 767px) {
  #Content9_0 .image-wrapper > .name-wrapper > .lxn3cbtz5g6-editor_css {
    display: none;
  }
}
#Content9_0 .image-wrapper > .name-wrapper > .lxn387qf8jk-editor_css {
  opacity: 0;
}

@media screen and (max-width: 767px) {
  #Content9_0 .image-wrapper > .name-wrapper > .lxn387qf8jk-editor_css {
    width: 100%;
    opacity: 0.99;
  }
}
#Content9_0 .image-wrapper > .name-wrapper > .lxn3bxq887j-editor_css {
  opacity: 0;
}

@media screen and (max-width: 767px) {
  #Content9_0 .image-wrapper > .name-wrapper > .lxn3bxq887j-editor_css {
    width: 100%;
    opacity: 0.99;
  }
}
#Content9_0 .image-wrapper > .name-wrapper > .lxn3ce4kon-editor_css {
  opacity: 0;
}

@media screen and (max-width: 767px) {
  #Content9_0 .image-wrapper > .name-wrapper > .lxn3ce4kon-editor_css {
    width: 100%;
    opacity: 0.99;
  }
}
#Pricing1_0.lxn3yyh8jgj-editor_css {
  background-color: rgb(250, 250, 250);
}
#Pricing1_0
  .pricing1-block-box
  > .pricing1-top-wrapper
  > .lxn44khlux-editor_css {
  font-size: 22px;
}
#Pricing1_0
  .pricing1-block-box
  > .pricing1-top-wrapper
  > .lxn440w40l-editor_css {
  font-size: 22px;
  color: #e48216;
}
#Pricing1_0
  .pricing1-block-box
  > .pricing1-top-wrapper
  > .lxn4c7u35ea-editor_css {
  font-size: 22px;
}
#Pricing1_0 .ant-col > .pricing1-block-box > .lxn4efh6ws7-editor_css {
  text-align: left;
}
#Pricing1_0
  .pricing1-block-box
  > .pricing1-button-wrapper
  > .lxn49o58a0e-editor_css {
  opacity: 0.3;
}
#Pricing1_0 .ant-col > .pricing1-block-box > .lxn50q58vmr-editor_css {
  text-align: left;
}
#Nav2_0 .home-page > .lxnly6i9nsi-editor_css {
  width: 50px;
}
#Feature6_0 .ant-row > .ant-col > .lxnmolw9v5-editor_css {
  color: #1076f6;
}
#Feature6_0 .ant-row > .ant-col > .lxnmoh5vde7-editor_css {
  color: #1076f6;
}
#Feature6_0 .ant-row > .ant-col > .lxnmngrvdv-editor_css {
  color: #1076f6;
}
#Feature6_0
  .feature6-title-wrapper
  > .feature6-title
  > .lxnmopnwfnj-editor_css {
  color: #1076f6;
}
#Feature6_0
  .feature6-title
  > .feature6-title-bar-wrapper
  > .lxnmouimqv9-editor_css {
  background-color: #1076f6;
}
#Banner5_0
  .banner5-title-wrapper
  > .banner5-button-wrapper
  > .lxm187o3t1f-editor_css {
  background-clip: ;
  background-color: rgb(47, 163, 235);
  background-image: linear-gradient(
    to right bottom,
    #aedbf5 0%,
    #10c0f6 0%,
    rgb(148, 207, 248) 98.15%
  );
}
#Content9_0 .timeline > .block-wrapper > .lxnowcbf4jl-editor_css {
}
#Content9_0 .home-page > .timeline > .lxnlivamb7f-editor_css {
  min-height: 100px;
  margin: 0px 0px 10px;
}

@media screen and (max-width: 767px) {
  #Content9_0 .home-page > .timeline > .lxnlivamb7f-editor_css {
    min-height: 90px;
    margin: 0px 0px 40px;
  }
}
#Content9_0 .home-page > .timeline > .lxnp1k4xcr-editor_css {
  min-height: 100px;
  margin: 0 0;
}
#Content9_0 .home-page > .timeline > .lxnoys3xuck-editor_css {
  min-height: 80px;
  margin: 0px 0px 0;
}

@media screen and (max-width: 767px) {
  #Content9_0 .home-page > .timeline > .lxnoys3xuck-editor_css {
    min-height: 140px;
    margin: 0px 0px 40px;
  }
}
#Content9_0.lxn3zhwaszi-editor_css {
  background-color: rgba(0, 0, 0, 0);
}
